import React from 'react';
import styled from 'styled-components';
import SEO from '../components/seo';
import Layout from '../components/layout';
import MyImage from '../images/hero-image.jpg';
import { headingMixin, paragraphMixin, subheadingMixin, OutboundButton } from '../theme/mixins';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { FeaturesList } from '../components/features-list';

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 540px;
  color: white;
  overflow: hidden;

  .background-image {
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-width: 768px) {
    height: 340px;
  }

  .text-container {
    position: absolute;
    z-index: 2;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;
    width: 100%;
    height: 100%;

    .booking-button {
      margin-top: 30px;
    }

    &::before {
      content: '';
      opacity: 0.6;
      background-color: #6e00fb;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;

      @keyframes fadein {
        from {
          opacity: 0;
        }
        to {
          opacity: 0.6;
        }
      }
      animation: fadein 0.5s;
    }

    .banner-title {
      font-size: ${(props) => props.theme.fontSize['4xl']};
      @media only screen and (max-width: 900px) {
        font-size: ${(props) => props.theme.fontSize['2xl']};
      }
      font-weight: bold;
      margin: 0 32px;
    }
    .banner-subtitle {
      margin: 0 32px;
      font-size: ${(props) => props.theme.fontSize['xl']};
      @media only screen and (max-width: 900px) {
        font-size: ${(props) => props.theme.fontSize.base};
      }
    }
  }
`;

const IntroSection = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 64px;
  margin: 64px 100px;

  @media only screen and (max-width: 768px) {
    text-align: center;
    grid-gap: 32px;
    margin-bottom: 32px;
  }

  .map {
    iframe {
      width: 100%;
      height: 500px;
    }
  }

  .page-title {
    margin-bottom: 12px;
    ${headingMixin}
  }
  p {
    ${paragraphMixin}
  }

  .contact-details {
    @media only screen and (max-width: 768px) {
      margin: 16px 0 0 0;
      text-align: center;
    }
    h2 {
      margin-bottom: 12px;
      ${subheadingMixin}
    }
    b {
      font-weight: 700;
    }
    .contact-management {
      margin-bottom: 24px;
    }
    ul {
      display: flex;
      @media only screen and (max-width: 768px) {
        margin-top: 32px;
      }
      flex-direction: column;
      align-items: flex-start;

      .contact-item {
        flex-shrink: 0;
        text-align: left;
        margin-bottom: 12px;
        ${paragraphMixin}
        a {
          text-decoration: none;
          color: #00abfa;
          margin-right: 4px;
          &:hover {
            filter: brightness(70%);
          }
        }
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      svg {
        margin-right: 16px;
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    margin-left: 30px;
    margin-right: 30px;
    grid-template-columns: 1fr;
    .map {
      iframe {
        width: 100%;
        height: 300px;
      }
    }
  }
`;

const IndexPage = () => {
  return (
    <Layout useContentWrapper={false}>
      <SEO title="Home" />
      <Banner>
        <img className="background-image" aria-hidden="true" src={MyImage} loading="eager" alt="" />
        <div className="text-container">
          <p className="banner-title">Peterborough Caravan Park</p>
          <p className="banner-subtitle">Enjoy beautiful views and peace and quiet</p>
          <OutboundButton className="booking-button" href="#booking">
            Make a booking
          </OutboundButton>
        </div>
      </Banner>
      <IntroSection>
        <div>
          <p className="page-title">Have a nice stay in Peterborough</p>
          <p>
            We would like you to enjoy your stay at Peterborough. The Park is quiet with no busy
            traffic passing by. Panoramic landscape, peaceful and relaxing atmosphere. Friendly
            staff and great facilities for all to use.{' '}
          </p>
          <br />
          <p>
            Alongside the caravan park is one of the finest recreational areas in the north with a
            lake, island, playground and barbeque area. A public pool (open summer months only) is a
            short walk from the park.
          </p>
          <div id="booking"></div>
          <br />
          <section className="contact-details">
            <h2>Call or email for a booking</h2>
            <div>
              <div className="contact-management">
                <p>
                  <b>Owners:</b> Neil & Robyn Walker
                </p>
                <p>
                  <b>Park Managers:</b> Robert & Jacki Williams
                </p>
                <p>
                  <b>Office Hours:</b> 8am - 6pm
                </p>
              </div>
              <ul>
                <li className="contact-item">
                  <span>
                    <AddressIcon /> <p>36 Grove St, Peterborough, South Australia 5422 Australia</p>
                  </span>
                </li>
                <li className="contact-item">
                  <span>
                    <PhoneIcon /> <p>08 8651 2545 (After hours will divert)</p>
                  </span>
                </li>
                <li className="contact-item">
                  <span>
                    <MailIcon />{' '}
                    <OutboundLink href="mailto:pbcp5422@gmail.com">pbcp5422@gmail.com</OutboundLink>
                  </span>
                </li>
              </ul>
            </div>
          </section>
        </div>
        <div className="map">
          <iframe
            title="Google Maps"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: 0 }}
            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJKb_Hu9yFvmoRFKTZwicG_Ps&key=AIzaSyBDS9p9xebSl3HZG_cdQz1rTdKkqfs1PFA"
          ></iframe>
        </div>
      </IntroSection>
      <FeaturesList heading="Quick and comfortable access to beautiful rural views" />
    </Layout>
  );
};

const AddressIcon = () => (
  <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 10C19 18 10 25 10 25C10 25 1 18 1 10C1 5 5 1 10 1C15 1 19 5 19 10Z"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13Z"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

const PhoneIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.9999 16.92V19.92C22.0011 20.1985 21.944 20.4742 21.8324 20.7294C21.7209 20.9845 21.5572 21.2136 21.352 21.4019C21.1468 21.5901 20.9045 21.7335 20.6407 21.8227C20.3769 21.9119 20.0973 21.9451 19.8199 21.92C16.7428 21.5856 13.7869 20.5342 11.1899 18.85C8.77376 17.3147 6.72527 15.2662 5.18993 12.85C3.49991 10.2412 2.44818 7.271 2.11993 4.18001C2.09494 3.90347 2.12781 3.62477 2.21643 3.36163C2.30506 3.09849 2.4475 2.85669 2.6347 2.65163C2.82189 2.44656 3.04974 2.28271 3.30372 2.17053C3.55771 2.05834 3.83227 2.00027 4.10993 2.00001H7.10993C7.59524 1.99523 8.06572 2.16708 8.43369 2.48354C8.80166 2.79999 9.04201 3.23945 9.10993 3.72001C9.23656 4.68007 9.47138 5.62273 9.80993 6.53001C9.94448 6.88793 9.9736 7.27692 9.89384 7.65089C9.81408 8.02485 9.6288 8.36812 9.35993 8.64001L8.08993 9.91001C9.51349 12.4136 11.5864 14.4865 14.0899 15.91L15.3599 14.64C15.6318 14.3711 15.9751 14.1859 16.3491 14.1061C16.723 14.0263 17.112 14.0555 17.4699 14.19C18.3772 14.5286 19.3199 14.7634 20.2799 14.89C20.7657 14.9585 21.2093 15.2032 21.5265 15.5775C21.8436 15.9518 22.0121 16.4296 21.9999 16.92Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const MailIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 6L12 13L2 6"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IndexPage;
