import React from 'react';
import styled from 'styled-components';
import { subheadingMixin } from '../theme/mixins';

export function FeaturesList({ className, heading }) {
  const features = [
    'Camp Kitchen',
    'Disabled Bathroom',
    'BBQ Area',
    'Pet friendly',
    'Mini golf',
    'Ensuite Cabins',
    'Free Wi-Fi',
    'Wheelchair Access',
    'Toilet and shower amenities',
    'Swimming Pool',
    'Washing Machines',
  ];

  return (
    <FeaturesSection className={className}>
      <h2>{heading}</h2>
      <ul className="feature-list">
        {features.map((x) => (
          <li key={x}>
            <FeatureIcon />
            {x}
          </li>
        ))}
      </ul>
    </FeaturesSection>
  );
}

const FeatureIcon = () => (
  <svg viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.009 1.06578C10.5624 1.06462 4.75005 4.9477 2.28307 10.9037C-0.183444 16.86 1.18088 23.7155 5.74078 28.2726C11.9643 34.4962 22.0542 34.4962 28.2775 28.2726C34.5007 22.0496 34.5007 11.9597 28.2775 5.7362C25.2955 2.73729 21.238 1.05556 17.009 1.06578ZM17.009 31.4606C9.03968 31.4606 2.5531 24.9738 2.5531 17.0044C2.5531 9.0351 9.03968 2.54852 17.009 2.54852C24.9783 2.54852 31.4651 9.0351 31.4651 17.0044C31.4651 24.9738 24.9783 31.4606 17.009 31.4606Z"
      fill="#40E026"
    />
    <path
      d="M24.5855 12.1043L15.015 21.2522L9.79591 16.0406C9.5073 15.752 9.03944 15.752 8.7506 16.0406C8.462 16.3294 8.462 16.7973 8.7506 17.0861L14.4812 22.8167C14.6203 22.9565 14.8102 23.034 15.0076 23.0315C15.1989 23.0333 15.383 22.9586 15.5191 22.8239L25.6085 13.1794C25.901 12.8959 25.9105 12.4296 25.6301 12.1345C25.3491 11.8397 24.8831 11.8262 24.5855 12.1043Z"
      fill="#40E026"
    />
  </svg>
);

const FeaturesSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 24px;
  margin-bottom: 128px;
  h2 {
    margin-bottom: 42px;
    text-align: center;
    ${subheadingMixin}
  }
  .feature-list {
    display: grid;
    list-style-type: none;
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr 1fr;
    @media only screen and (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }
    li {
      display: flex;
      align-items: center;

      svg {
        flex-shrink: 0;
        width: 34px;
        height: 34px;
        margin-right: 12px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 64px;
  }
`;
